import { Box, BoxProps, ChakraProps, HStack } from "@biblioteksentralen/react";
import { targetAudiences } from "@libry-content/common";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { useSitePalette } from "../../utils/useSitePalette";
import { ResolvedEventSummary } from "./sanityQuery";

export const EventAudienceAndTypeBadges = ({
  event,
  ...chakraProps
}: { event: ResolvedEventSummary } & ChakraProps) => {
  const { ts } = useTranslation();
  const palette = useSitePalette();

  return (
    <HStack paddingTop=".25rem" flexWrap="wrap" {...chakraProps}>
      <Badge {...palette.colors.lightaccent1.css}>{ts(event.eventType)}</Badge>
      {event.targetAudiences?.map((code) => (
        <Badge {...palette.colors.lightaccent2.css} key={code}>
          {ts(targetAudiences[code])}
        </Badge>
      ))}
    </HStack>
  );
};

const Badge = (props: BoxProps) => (
  <Box fontWeight={600} padding=".1em .5em" borderRadius=".25em" textTransform="uppercase" {...props} />
);
