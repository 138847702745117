import { DateHelper } from "@libry-content/common";
import { FrontendLocale } from "@libry-content/localization";
import { formatDate } from "../../utils/date";
import { ResolvedEvent, ResolvedEventSummary } from "./sanityQuery";

export const eventTimeString = (event: ResolvedEventSummary): string =>
  event.eventEnd ? `${event.eventStart} - ${event.eventEnd}` : event.eventStart ?? "";

export const isThisYear = (dateString: string | undefined): boolean =>
  dateString ? new Date(dateString).getFullYear() === new Date().getFullYear() : true;

export const getDateStringFormat = (showYear: boolean, allDay?: boolean): string => {
  if (allDay) return showYear ? "PP" : "EEEE, do MMMM";
  return showYear ? "EEEE, PPP" : "EEEE, do MMMM";
};

// This assumes repeated events have aquired a startDate using resolveEventGroqProjection,
// otherwise it would need extra handling
export const eventDateString = (languageCode: FrontendLocale, event: ResolvedEventSummary): string => {
  const showYear = !isThisYear(event.startDate) || !isThisYear(event.endDate);
  const singleDayFormat = getDateStringFormat(showYear);
  const allDayFormat = getDateStringFormat(showYear, true);

  if (event.allDay && event.startDate !== event.endDate) {
    const startDateString = formatDate(languageCode, allDayFormat, event.startDate);
    const endDateString = formatDate(languageCode, allDayFormat, event.endDate);
    return `${startDateString} til ${endDateString}`;
  }

  return formatDate(languageCode, singleDayFormat, event.startDate);
};

export const eventIsFinished = ({
  allDay,
  endDate,
  startDate,
}: Pick<ResolvedEvent, "allDay" | "endDate" | "startDate">): boolean => {
  if (allDay && endDate) {
    return !new DateHelper(endDate).isTodayOrLater;
  }

  return !!startDate && !new DateHelper(startDate).isTodayOrLater;
};

export const eventIsOpen = (eventStatus: ResolvedEvent["eventStatus"]): boolean =>
  !eventStatus || eventStatus === "available";

export const eventIsCancelled = (eventStatus: ResolvedEvent["eventStatus"]) => eventStatus === "cancelled";

export const eventIsFull = (eventStatus: ResolvedEvent["eventStatus"]): boolean => eventStatus === "full";

export const looksLikeEmail = (registrationUrlOrEmail: string | undefined) => !!registrationUrlOrEmail?.includes("@");
