import { Box, colors, Text, TextProps } from "@biblioteksentralen/react";
import { Event } from "@libry-content/types";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { ResolvedEventSummary } from "./sanityQuery";
import { eventIsCancelled, eventIsFull, eventIsOpen } from "./utils";

export const EventStatusTitlePrefix = ({ eventStatus }: { eventStatus: Event["eventStatus"] }) => {
  const { t } = useTranslation();
  if (eventIsCancelled(eventStatus)) return <>{t("AVLYST")} - </>;
  if (eventIsFull(eventStatus)) return <>{t("FULLT")} - </>;
  return null;
};

export const EventStatusBadge = ({ eventStatus, ...rest }: TextProps & Pick<ResolvedEventSummary, "eventStatus">) => {
  if (eventIsOpen(eventStatus)) return null;
  return (
    <Box position="absolute" left="0" top="0" width="100%" height="100%" overflow="hidden">
      <Text
        position="absolute"
        textAlign="center"
        right="0"
        fontSize="1em"
        fontWeight="600"
        width="10rem"
        color={eventIsCancelled(eventStatus) ? "white" : "black"}
        background={eventIsCancelled(eventStatus) ? colors.statusRed : "#FFC970"}
        boxShadow={eventIsCancelled(eventStatus) ? `0 0 0 100vmax ${colors.statusRed}` : "0 0 0 100vmax #FFC970"}
        transform="rotate(45deg)"
        transformOrigin="3rem 5rem"
        textTransform="uppercase"
        padding=".3rem 0"
        clipPath="inset(0 -100%)"
        sx={{
          "::before, ::after": {
            content: '""',
            width: "100%",
            height: "100%",
            position: "absolute",
            background: "inherit",
            top: 0,
            margin: "0 -1px",
          },
          "::before": {
            right: "100%",
          },
          "::after": {
            left: "100%",
          },
        }}
        {...rest}
      >
        {eventIsCancelled(eventStatus) ? "avlyst" : "fullt"}
      </Text>
    </Box>
  );
};
